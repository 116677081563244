import React from "react"
import SectionHeader from "../../components/UI/elements/SectionHeader"
import Layout from "../../components/UI/layout"

const Reports = () => {
  return (
    <Layout>
      <SectionHeader headerText="Reports" />
    </Layout>
  )
}

export default Reports
